:root {
  --spot-building-card-section-width: 490px;
  --space-details-popup-height: 900px;
  --request-info-popup-height: 90vh;
  --space-details-popup-width: 840px;
  --request-info-popup-width: 840px;
  --spot-building-aspect-radio: 3 / 4;
  --video-tour-popup-height: 683px;
  --video-tour-popup-width: 1080px;
  --company-primary-photo-width: 820px;
  --company-secondary-photo-width: 344px;
  --max-width-page-desktop: 1330px;
}
