/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$break-large: 500px;

html {
  font-size: 15px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #11334c;
}

p {
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 4.209em;
}

h2 {
  font-size: 3.157em;
}

h3 {
  font-size: 2.369em;
}

h4 {
  font-size: 1.777em;
}

h5 {
  font-size: 1.333em;
}

small,
.text_small {
  font-size: 0.75em;
}

* {
  font-family: "Open Sans", sans-serif !important;
}

.standard-shadow {
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.light-shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.light-shadow-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

ion-slides {
  span.swiper-pagination-bullet {
    opacity: 0.9 !important;
  }

  span.swiper-pagination-bullet .swiper-pagination-bullet-active {
    background: #ff9900 !important;
  }

  span.swiper-pagination-bullet-active {
    background: #043650 !important;
  }
}

@media screen and (max-width: $break-large) {
  .mobile-width {
    width: 100% !important;
  }

  .mobile-padding {
    padding-bottom: 25px;
  }

  .just-center {
    color: #ff9900 !important;
  }

  .justify {
    display: unset !important;
    justify-content: unset !important;
  }

  .button-solid {
    --background: #043650;
  }

  .listing-btn-height:hover {
    background-color: #043650 !important;

    .purchase-block-8 {
      color: #ff9900;
    }
  }

  .list-addition {
    height: 170px !important;
  }

  .img-respons {
    display: block;
    width: 100% !important;
    height: calc(100vw * 3 / 4) !important;
    overflow: hidden;
  }

  .slide-height {
    img {
      height: calc(100vw * 3 / 4) !important;
      width: 100% !important;
      -o-object-fit: cover !important;
      object-fit: cover !important;
    }
  }

  .one-photo-static-map {
    img {
      height: calc(100vw * 3 / 4) !important;
      width: 100% !important;
      -o-object-fit: unset !important;
      object-fit: unset !important;
    }
  }

  .detail-flex {
    height: calc(100vw * 3 / 4) !important;
    width: 100% !important;
  }

  .our-partners {
    .partners-card-block {
      height: calc(100vw * 10 / 15) !important;
    }

    .partners-card-header {
      height: calc(100vw * 10 / 15) !important;
    }

    .partners-content {
      padding-top: 45px !important;
    }

    .card-alignment {
      height: unset !important;
    }

    .image-dots {
      top: 114% !important;
    }

    .hidden-content {
      height: 190px !important;
    }

    .img-block {
      width: 100% !important;
      height: calc(100vw * 10 / 15) !important;

      img {
        width: 100% !important;
        height: calc(100vw * 10 / 15) !important;
        object-fit: cover !important;
      }
    }

    .img-block-glass {
      height: calc(100vw * 10 / 15) !important;
      width: 100% !important;

      img {
        width: 100% !important;
        height: calc(100vw * 10 / 15) !important;
        object-fit: cover !important;
      }
    }
  }

  .card-block {
    width: 100% !important;
  }

  .card-alignment {
    width: 100% !important;
    border: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
    margin-top: unset !important;
  }

  app-listing-card {
    width: 100% !important;

    mat-card {
      width: 100% !important;
      /*height: unset !important;*/
      margin-left: unset !important;
      margin-top: unset !important;

      .region-bar-default {
        width: 100% !important;
      }

      .image-height-card {
        height: calc(100vw * 3 / 4) !important;
      }

      .image-details-height-card {
        height: calc(88.8vw * 3 / 4) !important;
      }

      .swiper-slide {
        img {
          height: calc(100vw * 3 / 4) !important;
          width: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }

  app-spot-building-card {
    width: 100% !important;

    mat-card {
      width: 100% !important;
      margin-left: unset !important;

      .region-bar-default {
        width: 100% !important;
      }

      .image-details-height-card {
        height: calc(88.8vw * 3 / 4) !important;
      }

      .slider-width-card {
        width: calc(100vw - 22px);
      }

      .swiper-slide {
        img {
          height: calc(100vw * 3 / 4) !important;
          width: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }

  app-spot-featured-card {
    width: 100% !important;

    mat-card {
      width: 100% !important;
      /*height: unset !important;*/
      margin-left: unset !important;

      // margin-top: unset !important;
      .region-bar-default {
        width: 100% !important;
      }

      .slider-width-card {
        width: calc(100vw - 22px);
      }

      .swiper-slide {
        img {
          height: calc(100vw * 9 / 16) !important;
          width: 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }
}

.siila-blue {
  color: #043650;
}

.my-listing-company {
  .ionic-selectable-inner {
    height: 42px;
  }
}

.ionic-selectable-inner {
  padding-left: 5px;
}

.ionic-selectable-value {
  text-transform: capitalize !important;
}

.siila-blue-bg {
  background: #043650;
}

.siila-orange {
  color: #ff9900;
}

.siila-orange-bg {
  background: #ff9900;
}

.siila-submit {
  background: #ff9900;
  text-transform: uppercase;
  color: white;
}

ion-segment-button {
  --indicator-color: transparent !important;
  --indicator-color-checked: transparent !important;
}

.modal-full-screen {
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
}

.highlight {
  background-color: rgba(255, 153, 0, 0.41);
}

.highlight-match {
  color: blue;
  font-weight: bold;
}

.popover-location-search {
  .popover-arrow {
    display: none;
  }

  .popover-viewport {
    height: 95%;
  }

  .popover-content {
    width: 80vw !important;
    max-width: 600px;
    top: 50% !important;
    left: 50% !important;
    overflow: hidden;
    transform: translate(-50%, -50%) !important;
    -webkit-font-smoothing: subpixel-antialiased;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ion-grid {
      overflow-y: auto;
      overflow-x: hidden;
      //min-height: 80%;
      padding-left: 33px;
      padding-right: 33px;
    }
  }
}

.popover-type-search {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 85vw !important;
    max-width: 600px;
    top: 50% !important;
    --min-height: 235px;
    overflow-y: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-type-search-filters {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 100vw !important;
    max-width: 1000px;
    top: 50% !important;
    --min-height: 585px;
    overflow-y: hidden;
    overflow-x: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-type-Space {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 80vw !important;
    max-width: 800px;
    top: 50% !important;
    --min-height: 605px;
    overflow-y: hidden;
    overflow-x: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

@media only screen and (min-width: 1600px) {
  .popover-type-search-filters {
    .popover-arrow {
      display: none;
    }

    .popover-content {
      width: 100vw !important;
      max-width: 1400px;
      top: 50% !important;
      --min-height: 829px;
      overflow-y: hidden;
      overflow-x: hidden;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
}

@media only screen and (min-width: 1600px) {
  .popover-type-Space {
    .popover-arrow {
      display: none;
    }

    .popover-content {
      width: 100vw !important;
      max-width: 1400px;
      top: 50% !important;
      --min-height: 829px;
      overflow-y: hidden;
      overflow-x: hidden;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
}

.coworking-popover {
  @extend .popover-type-search;

  .popover-content {
    bottom: unset !important;
  }
}

.space-detail-popover {
  @extend .popover-type-search;

  .popover-content {
    max-width: var(--space-details-popup-width);
    min-width: var(--space-details-popup-width);
    //--min-height: var(--space-details-popup-height);
    max-height: var(--space-details-popup-height);
    height: fit-content !important;
    overflow-y: auto;
  }

  @media only screen and (max-width: 999px) {
    .popover-content {
      min-width: 100%;
      height: 86vh;
      background: white;
    }
  }
}

.request-info-popover {
  @extend .popover-type-search;

  .popover-content {
    max-width: var(--request-info-popup-width);
    min-width: var(--request-info-popup-width);
    --min-height: var(--space-details-popup-height);
  }

  @media only screen and (max-width: 999px) {
    .popover-content {
      min-width: 100%;
      height: 90vh;
      background: white;
      margin-top: 18px;
    }
  }
}

.social-media-popover {
  @extend .popover-type-search;

  .popover-content {
    min-width: 550px;
    --min-height: 240px;
    max-height: 240px;
  }

  @media only screen and (max-width: 999px) {
    .popover-content {
      min-width: 100%;
      background: white;
      --min-height: 322px;
      max-height: 280px;
    }
  }
}

.gallery-img-popover {
  @extend .popover-type-search;
  --max-height: 100% !important;
  --background: rgba(0, 0, 0, 0.8);

  .popover-content {
    max-width: var(--space-details-popup-width);
    min-width: var(--space-details-popup-width);
    height: fit-content;
  }

  @media only screen and (max-width: 999px) {
    .popover-content {
      min-width: 100%;
      height: 100%;
    }
  }
}

.video-player-popup {
  @extend .popover-type-search;

  .popover-content {
    //max-width: var(--video-tour-popup-width);
    //min-width: var(--video-tour-popup-width);
    max-height: var(--video-tour-popup-height);
    //min-height: var(--video-tour-popup-height);
  }
}

.property-type-popover {
  @extend .popover-type-search;

  .popover-content {
    --min-height: 260px;
  }
}

.listing-type-popover {
  @extend .popover-type-search;

  .popover-content {
    --min-height: 260px;
  }
}

.listing-type-popover {
  @extend .popover-type-search-filters;

  .popover-content {
    --min-height: 605px;
  }
}

@media only screen and (min-width: 1600px) {
  .listing-type-popover {
    @extend .popover-type-search-filters;

    .popover-content {
      --min-height: 829px;
    }
  }
}

.popover-property-search {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 85vw !important;
    max-width: 600px;
    top: 50% !important;
    min-height: 400px;
    overflow-y: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-remove-credits {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 85vw !important;
    max-width: 600px;
    top: 50% !important;
    min-height: 135px;
    height: 135 px !important;
    overflow-y: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-renewal {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: calc(100vw - 50px);
    --max-width: 500px;
    z-index: 9999 !important;
    top: 50% !important;
    --min-height: 475px;
    overflow-y: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-renew-extend {
  .popover-viewport {
    height: 100%;
  }

  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: calc(100vw - 50px);
    --max-width: 428px;
    z-index: 9999 !important;
    top: 50% !important;
    height: 400px;
    overflow-y: hidden;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.popover-agent-email {
  .popover-arrow {
    display: none;
  }

  .popover-content {
    width: 85vw !important;
    top: 50% !important;
    overflow-y: hidden;
    --min-height: 400px;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.scroll-content {
  padding-bottom: 0 !important;
}

// ion-spinner > svg {
//   width: 80px !important;
//   height: 80px !important;
// }

ion-spinner {
  width: 100px !important;
  height: 56px !important;
}

.action-sheet-group-cancel {
  display: none;
}

.remove-ok {
  overflow-y: hidden;

  ion-item {
    --inner-border-width: 0;
    font-size: 14px;
  }

  .item-radio-checked {
    color: #043650;
  }

  .radio-checked {
    --color-checked: #043650;
  }

  .alert-button {
    color: #043650;
  }

  .popover-arrow {
    display: none;
  }

  .popover-content {
    overflow-y: hidden;
    width: 65vw !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ion-list {
      margin-bottom: unset !important;
    }

    ion-grid {
      min-height: 100%;
      padding: unset;
    }
  }
}

.top-search-btn {
  overflow-y: hidden;

  ion-item {
    --inner-border-width: 0;
    font-size: 18px;
  }

  .item-radio-checked {
    color: black;
  }

  .radio-checked {
    --color-checked: #043650;
  }

  .alert-button {
    color: #043650;
  }

  .popover-arrow {
    display: none;
  }

  .popover-content {
    overflow-y: hidden;
    width: 65vw !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ion-list {
      margin-bottom: unset !important;
    }

    ion-grid {
      min-height: 100%;
      padding: unset;
    }
  }
}

img.ng-lazyloaded {
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.toast-alert {
  text-align: center;
  font-size: 14px;
}

.header-alert-prompt {
  .alert-title {
    color: #ff9900;
  }

  .alert-message {
    color: grey;
  }
}

.spin-loading {
  --background: transparent;
  --spinner-color: #ff9900;
  background-color: transparent;
  box-shadow: none;
}

ion-loading.spin-loading {
  .loading-wrapper {
    background: transparent;
    box-shadow: none;

    .spinner-crescent circle {
      stroke-width: 8px;
    }
  }
}

.swiper-wrapper {
  margin-bottom: 40px;
}

*:focus {
  outline: none !important;
  border: 0 !important;
}

ion-button {
  text-transform: none !important;
}

ion-item {
  text-transform: capitalize !important;
}

.new-version {
  ion-item {
    --background: orange;
  }

  ion-item.item-native {
    padding: unset;
  }
}

button,
[type="button"],
// 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: none !important; // 2
}

ion-footer {
  ion-item {
    text-transform: capitalize;
  }
}

.capitalize {
  text-transform: capitalize;
}

.pointer-cursor {
  cursor: pointer;
}

.flex-row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
}

.flex-col-nowrap {
  display: flex;
  flex-flow: column nowrap;
}

.flex-col-wrap {
  display: flex;
  flex-flow: column wrap;
}

.right-float {
  float: right;
}

.inline-flex-nowrap {
  display: inline-flex;
  flex-flow: row nowrap;
}

.flex-row-justify-end-nowrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.flex-row-justify-center {
  display: flex;
  justify-content: center;
}

.flex-row-align-evenly {
  @extend .flex-row-wrap;
  justify-content: space-evenly;
}

.comma span:not(:last-child):after {
  content: ",";
}

@media only screen and (min-width: 415px) {
  .change-email-modal {
    --width: 550px;
    --height: 390px;
  }

  .generic-info-modal {
    --width: 500px;
    --height: 210px;
  }

  .save-info-modal {
    --width: 500px;
    --height: 260px;
  }

  .change-password-modal {
    --width: 500px;
    --height: 460px;
  }

  .edit-image-modal {
    --width: 600px;
    --height: 96vh;

    .control-btn {
      justify-content: flex-end !important;
      margin-right: 10px !important;
      padding: 10px !important;
    }

    @media only screen and (max-height: 820px) {
      image-cropper {
        max-height: 55vh;
      }
    }

    @media only screen and (min-height: 820px) {
      image-cropper {
        max-height: 60vh;
      }
    }
  }

  .edit-crop-image-modal {
    --width: 600px;
    --height: 66vh;

    @media only screen and (max-height: 820px) {
      image-cropper {
        max-height: 55vh;
      }

      crop-image-label {
        padding-right: 10px;
        padding-top: 0px;
      }
    }

    @media only screen and (min-height: 820px) {
      image-cropper {
        max-height: 60vh;
      }
    }
  }

  /* Desktop */
  .multiple-images-modal {
    --width: 600px;
    --height: 450px;

    .multiple-files-content {
      max-height: 290px;
    }
  }

  .prompt-save-change-modal {
    --width: 500px;
    --height: 210px;
  }
}

@media only screen and (max-width: 415px) {
  .change-email-modal {
    --width: 350px;
    --height: 390px;
  }

  .generic-info-modal {
    --width: 350px;
    --height: 220px;
  }

  .save-info-modal {
    --width: 350px;
    --height: 270px;
  }

  .change-password-modal {
    --width: 350px;
    --height: 490px;
  }

  .edit-image-modal {
    --width: 100%;
    --height: 630px;

    image-cropper {
      max-height: 345px;
    }
  }

  .edit-crop-image-modal {
    --width: 100%;
    --height: 520px;

    image-cropper {
      max-height: 345px;
    }
  }

  .multiple-images-modal {
    --width: 100%;
    --height: 420px;

    .multiple-files-content {
      max-height: 240px;
    }
  }

  .prompt-save-change-modal {
    --width: 370px;
    --height: 250px;
  }
}

ion-alert {
  z-index: 999999 !important;
}

.ion-alert-listings {
  .alert-wrapper .alert-head {
    text-align: center;
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 9997;
}

::ng-deep .ion-alert-listings {
  z-index: 9999999 !important;
  text-align: center;
}

::ng-deep .alert-head.sc-ion-alert-md {
  text-align: center;
}

::ng-deep .alert-sub-title {
  text-align: center;
}

.ow-break-word {
  overflow-wrap: break-word;
  white-space: pre-line;
  width: 100% !important;
}

.tooltip-font {
  font-size: 14px;
}

.remove-underline {
  text-decoration: none !important;

  a:hover {
    text-decoration: none !important;
  }
}

.email-confirm-height {
  .alert-message {
    max-height: 290px;
    white-space: break-spaces;
  }
}

::ng-deep .alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

::ng-deep .alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::ng-deep .alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.searchbar-input.sc-ion-searchbar-md {
  padding-left: unset !important;
  padding-right: unset !important;
  padding-inline-start: 55px !important;
  padding-inline-end: 55px !important;
  cursor: pointer !important;
}

.snack-background-spot {
  background-color: #ff9900 !important;
  color: white;

  .mat-simple-snackbar-action {
    color: black !important;
  }
}

.row-border2 {
  border-top: 2px solid #bbb;
}

.content-text-align {
  margin-top: 10px;
}

.tittle-new-version {
  text-align: center;
  margin-bottom: 12px;
  font-size: 18px;
}

.mail-new-version {
  color: #ff9900 !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.flex-align-self-center {
  align-self: center;
}

.flex-align-content-end {
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.align-items-center {
  align-items: center;
}

@media only screen and (max-height: 1020px) {
  :root {
    --space-details-popup-height: 95vh;
    --space-details-popup-width: 840px;
    --request-info-popup-width: 840px;
  }
}

.spot-building-cards {
  .building-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #212529;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .building-title-new {
    @extend .building-title;
  }

  .card-subheader {
    font-size: 14px;
    line-height: 24px;
    color: #00334d;
  }

  .spot-building-main-info {
    font-size: 14px;
    line-height: 24px;
  }

  .main-info-gap {
    padding-left: 10px;
    font-weight: 400;
  }

  .row-top-separator {
    padding-top: 10px;
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  .padding-left-10 {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .spot__cards__section {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    column-gap: 20px;
    margin-top: 56px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1250px) {
  .spot__cards__section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    column-gap: 20px;
    margin-top: 56px;
  }
}

.more__results__section {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
}

.padding-left-10 {
  padding-left: 10px;
}

::ng-deep .pagination-control .page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: red !important;
  border-color: #009da0;
}

.pagination-control {
  justify-content: center;
}

.grid-two-cols {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.spot-info-window__container {
  width: 482px;
  height: 130px;
  column-gap: 20px;
  text-decoration: none;
  cursor: pointer;
}

.spot-info-window__mobilecontainer {
  width: 100%;
  height: auto;
  column-gap: 10px;
  text-decoration: none;
  cursor: pointer;
}

.spot-info-window__container:hover {
  text-decoration: none;
}

.spot-info-window__img {
  width: 100%;
  height: 130px;
  -o-object-fit: cover;
  object-fit: cover;
}

.spot-info-windowmobile__img {
  width: 100%;
  aspect-ratio: 4/3;
  -o-object-fit: cover;
  object-fit: cover;
}

.nowrap {
  white-space: nowrap;
}

.ellipsis__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spot-info-not-visible {
  visibility: hidden;
  height: 0px;
}

.spot-info-window__title {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #212529;

  .is-favorite {
    color: #ff9900;
  }

  .no-favorite {
    color: white;
  }

  &__favorite-button {
    stroke: #ff9900;
    stroke-width: 25px;
    width: 14px;
    height: 14px;
    align-self: center;
  }
}

.spot-info-window__header {
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #00334d;
}

.md-size {
  width: 14px;
  height: 14px;

  .icon-size {
    font-size: 13px;
  }
}

.spot-info-window__subheader {
  font-weight: 400;
  font-size: 11px;
  color: #212529;
}

.spot-info-window__datacontainer {
  justify-content: start;
  height: auto;
  margin-top: 10px;
  overflow: hidden;
}

.spot-info-window__topseparator {
  background: #ced4da;
  height: 1px;
  width: 100%;
  margin: 4px 0px 4px 0px;
}

.wa-icon-color {
  color: #6dc367;
  font-size: 18px;
  margin-bottom: -4px;
}

.agent-link {
  color: #0d6efd;
  cursor: pointer;
}

.btn-white {
  background: white !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #00334d;
  background: #ffffff;
  border: 1px solid #00334d;
  box-sizing: border-box;
  border-radius: 8px;
}

.btn-orange {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  background: #ff9900 !important;
  border-radius: 4px;
  margin: 0;
  letter-spacing: 0;
  color: #331f00;
}

.new-link-color {
  color: #007bff !important;
}

.investment__opportunity {
  text-align: justify;
  text-justify: inter-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: unset;
  min-height: 80px;
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212529;
}

.margin-top-48 {
  margin-top: 48px !important;
}

.loading__animation__section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 56px;
}

.btn-color-1 {
  color: #00334d;
  --color-hover: black;
  --border-color: #00334d;
  font-weight: 700;
}

.btn-color-2 {
  color: #331f00;
  --color-hover: white !important;
  --background: #ff9900 !important;
  font-weight: 700 !important;
  letter-spacing: 0px;
  --border-radius: 4px !important;
  height: 37px;
}

.row-separator-mobile {
  border: 1px solid #00334d;
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
  width: 14px;
  height: 14px;
  float: right;
  margin: 12px 20px 0px 0px;
}

.bold {
  font-weight: bold;
}

.zoom-map-container {
  top: 10px !important;
  right: 10px !important;
}

.zoom-map-separator {
  background: #e6e6e6;
  height: 1px;
  width: 100%;
}

.btn-cancel-set {
  background: #e9ecef;
  height: 37px;
  width: 86px;
  border-radius: 6px;
  color: #0d6efd;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 700;
}

.toast-alert-detail {
  --background: #d1e7dd;
  color: #198754;
  text-align: center;
}
